/*
* class AppFunctions
*/

var AppFunctions = function() {

    /*
    * Env variables
    */
    let xhrsearch;
    let ie = /MSIE \d|Trident.*rv:/.test(navigator.userAgent);
    // var slides = []

    /*
    * front
    */


    var controlResp = function()
    {
        if(window.innerWidth <= AppTools.getSize('sm')) {
            let divs = document.querySelectorAll('.max-content');
            [].forEach.call(divs, function(div) {

                div.classList.add('overflowed')
                
                let btn = document.createElement('button');
                let txt = document.createElement('span');
                txt.appendChild(document.createTextNode(div.dataset.more));
                btn.classList.add('see-more');
                btn.appendChild(txt);
                div.appendChild(btn);
        
                div.style.height = div.dataset.size ? div.dataset.size : '140px';
                    
                div.addEventListener('click', function(){
                    btn.style.display   = 'none';
                    div.style.height = 'auto';
                    div.classList.add('open')
                });
            });
        }

        if(AppTools.getTouchEvent()) {

            document.addEventListener('click', function (event) {
                
                if (!event.target.closest('.item_info')) return;
                
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            });
            
            document.addEventListener('touchstart', function (event) {
                
                let targets = document.querySelectorAll('.item_info.showmodal');
                [].forEach.call(targets, function(target){
                    target.classList.remove('showmodal');
                })
                
                if (!event.target.closest('.item_info')) return;
                
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                
                let target = event.target.closest('.item_info');
                target.classList.add('showmodal');
            });

            // document.addEventListener('touchend', function (event) {
            //     console.log('end');
            //     let targets = document.querySelectorAll('.item_info');
            //     [].forEach.call(targets, function(target){
            //         target.classList.remove('showmodal');
            //     })
            // });
        }
        
        // Exemple actions pour responsive
        // let togglemenu = document.getElementById('toggle_menu')
        // let menublock  = document.getElementById('resp_menu')
        // let holdmenu   = document.getElementById('hold_resp_menu')

        // var trait = function(event){
        //     var res = ['m','sm','s'];
        //     if( !res.includes(PAtools.getScreenType()) )
        //         return;
         
        //     event.preventDefault()

        //     if( menublock.classList.contains('open') ){
        //         menublock.classList.remove('open')
        //         document.body.classList.remove('overled')
        //     }
        //     else{
        //         menublock.classList.add('open')
        //         document.body.classList.add('overled')
        //     }
        // }

        // menublock.addEventListener('touchstart', trait)
        // menublock.addEventListener('click', trait)

        // holdmenu.addEventListener('click', function(event){
        //     event.stopPropagation()
        // })
        // holdmenu.addEventListener('touchstart', function(event){
        //     event.stopPropagation()
        // })
    }

    let controlToggles = function()
    {
        let toggles = document.querySelectorAll('.toggle-item .toggle-head');
        bindToggles(toggles);

        if(window.innerWidth <= AppTools.getSize('sm')){
            let toggles = document.querySelectorAll('.toggle-item-sm .toggle-head');
            bindToggles(toggles);
        }

        if(window.innerWidth <= AppTools.getSize('s')){
            let toggles = document.querySelectorAll('.toggle-item-s .toggle-head');
            bindToggles(toggles);
        }
        
        function bindToggles(elems){
            [].forEach.call(elems, function(toggle) {
                toggle.parentNode.classList.add('active-toggle');
                toggle.addEventListener('click', function(){
                
                    if(toggle.classList.contains('open')){
                        toggle.classList.remove('open')
                    }else{
                        toggle.classList.add('open')
                    }
                })
            })
        }
    }

    let controlSidebar = function(main, side)
    {   
        let maxval;
        main = main ? document.getElementById(main) : document.getElementById('right-side');
        side = side ? document.getElementById(side) : document.getElementById('holder-sidebar');
        
        if(!main || !side || side.classList.contains('disable'))
            return;
    
        if(window.innerWidth <= AppTools.getSize('m')){
            if(side.classList.contains('m-disable-sidebar'))
                return;
        }
        
        let sidepos        = side.getBoundingClientRect();
        let blocktop       = sidepos.top;
        let flag           = false;

        window.addEventListener('resize', function() {
            sidepos  = side.getBoundingClientRect();
            blocktop = sidepos.top;
        });

        if (window.scrollY > 0 && !flag && blocktop < 0){
            blocktop = blocktop + window.scrollY;
            flag = true;
        }

        window.addEventListener('scroll', function() {

            let scrolltop  = window.scrollY;
            let correction = 0;
            let windowh    = 0;//window.innerHeight;

            if(window.innerWidth > 768){

                let blockh    = side.offsetHeight;
                let maxval    = main.offsetHeight;
                let val       = ( scrolltop + windowh ) - ( (blocktop) - correction );

                if( val > 0 && ( blockh + val ) < maxval ){
                    side.style.position = 'fixed';
                    side.style.top      = correction+'px';
                    side.style.left     = sidepos.left+'px';
                    side.style.width    = sidepos.width+'px';
                    side.style.marginTop = 0;
                    side.classList.add('fixed');
                }
                else if(val > 0){
                    side.style.position  = 'relative';
                    side.style.left      = 0;
                    side.style.top       = 0;
                    side.style.marginTop = (maxval - blockh)+'px';
                }
                else{
                    side.style.position  = 'relative';
                    side.style.left      = 0;
                    side.style.top       = 0;
                    side.style.marginTop = 0;
                    side.style.width     = '';
                    side.classList.remove('fixed');
                }
            }
        });
    }

    /**
     * Actions page recherche
     */
    let controlSearch = function()
    {
        /**
         * Control submit
         */
        let forms = document.querySelectorAll('.form-search-filter');
        [].forEach.call(forms, function(form){
            form.addEventListener('change', function(){
                searchSubmit(true,1)
            })
        })
        
        
        /**
         * Gestion "cocher tout"
         */
        let globalchecks = document.querySelectorAll('.global-check');
        [].forEach.call(globalchecks, function(check) {
            
            let input  = check.querySelector('input');
            let target = document.getElementById(check.dataset.target);
            let checks = target.querySelectorAll('input[type="checkbox"]');

            //** Gestion check all */
            check.addEventListener('click', function(event){

                event.stopPropagation();
                // event.preventDefault();
                
                if(input.checked){
                    input.checked = false;
                }
                else{
                    input.checked = true;
                }
                
                [].forEach.call(checks, function(check) {
                    check.checked = input.checked;
                })
            });

            //** Gestion uuncheck */
            
            [].forEach.call(checks, function(inp) {
                
                inp.addEventListener('change', function(event){
                    if(!inp.checked){
                       input.checked = false;
                    }
                })
            })
        })

        /**
         * Gestion reset checkbox
         */
        let togglereset = document.querySelectorAll('.toggle-reset');
        [].forEach.call(togglereset, function(check) {
            check.addEventListener('click', function(event){
                
                let checks = check.parentNode.querySelectorAll('input[type="checkbox"]');
                
                [].forEach.call(checks, function(check) {
                    check.checked = false;
                })

                searchSubmit(true,1)
            })
        })

        /**
         * Gestion radio min/max
         */
        let radiosmin   = document.querySelectorAll('[name="price_min"]');
        let radiosmax   = document.querySelectorAll('[name="price_max"]');
        let watchfields = document.querySelectorAll('.watch-keyup');
        [].forEach.call(radiosmin, function(radio) {
            radio.addEventListener('change', function(){
                [].forEach.call(radiosmax, function(radio) {
                    radio.checked = false;
                });
                [].forEach.call(watchfields, function(field) {
                    field.value = '';
                });
            })
            radio.addEventListener('click', function(event){
                if (radio.prevcheck) {
                    radio.checked = false;
                    radio.prevcheck = false;
                    searchSubmit(true,1)
                } else {
                    radio.prevcheck = true;
                }
            })
        });
        [].forEach.call(radiosmax, function(radio) {
            radio.addEventListener('change', function(){
                [].forEach.call(radiosmin, function(radio) {
                    radio.checked = false;
                });
                [].forEach.call(watchfields, function(field) {
                    field.value = '';
                });
            })
            radio.addEventListener('click', function(event){
                if (radio.prevcheck) {
                    radio.checked = false;
                    radio.prevcheck = false;
                    searchSubmit(true,1)
                } else {
                    radio.prevcheck = true;
                }
            })
        });
        [].forEach.call(watchfields, function(field) {
            field.addEventListener('keyup', function(event){
                var charCode = (event.which) ? event.which : event.keyCode
                if (charCode > 31 && (charCode < 48 || charCode > 57)){
                    event.preventDefault();
                }
                [].forEach.call(radiosmax, function(radio) {
                    radio.checked = false;
                });
                [].forEach.call(radiosmin, function(radio) {
                    radio.checked = false;
                })
                searchSubmit(true,1)
            })
            field.addEventListener('change', function(event){
                event.stopImmediatePropagation();
                event.preventDefault();
                event.stopPropagation();
            })
        });

        /**
         * Reset total
         */
        document.addEventListener('click', function (event) {
            if (!event.target.closest('.reset-filters')) return;

            let forms = document.querySelectorAll('.form-search-filter');
            [].forEach.call(forms, function(form){
                    
                let checks = form.querySelectorAll('input[type="checkbox"],input[type="radio"]');
                [].forEach.call(checks, function(check) {
                    check.checked = false;
                })
            });
            [].forEach.call(watchfields, function(field) {
                field.value = '';
            });

            searchSubmit(true,1);
        });

        /**
         * Gestion pagination
         */
        document.addEventListener('click', function (event) {
            if (!event.target.closest('.item-pagination')) return;

            let elm  = event.target.closest('.item-pagination');
            let page = elm.dataset.page;
            searchSubmit(false,page);
        });
    }

    /**
     * Gestion submit search
     */
    let searchSubmit = function(reset,page)
    {
        //** Merge all form data */
        let forms = document.querySelectorAll('.form-search-filter');
        
        if(forms.length < 1)
            return;
        
        let data  = new FormData();
        let div   = document.getElementsByClassName('div_search')[0];

        loadingContent(div);
        
        [].forEach.call(forms, function(form){
            
            let dataform        = new FormData(form);
            let formDataEntries = dataform.entries();
            let formDataEntry   = formDataEntries.next();
            let pair;
                
            while (!formDataEntry.done) {
                pair = formDataEntry.value;
                data.append(pair[0], pair[1])
                formDataEntry = formDataEntries.next();
            }
        })

        data.set('page',page);

        //         for (const val of data) {
        //     console.log(val);
        // }
        
        //** Request */
        let request = new Promise(function (resolve, reject) {
                
            if(xhrsearch){
                xhrsearch.abort();
            }
            
            xhrsearch = new XMLHttpRequest();
            xhrsearch.open('POST', '/recherche/ajax', true);
            xhrsearch.onload = function () {
                
                if (this.status >= 200 && this.status < 300) {
                    let json = xhrsearch.response;
                    resolve(xhrsearch.response);
                }
            };
            
            xhrsearch.send(data);
        });

        request.then(function (response) {
            
            if(reset) {
                div.innerHTML = response;
            } else {
                // On supprime l'ancienne div de pagination
                let ghost = document.getElementsByClassName('ghost')
                if(ghost.length > 0)
                    ghost[0].remove();
                div.innerHTML = div.innerHTML + response;
            } 
            clearLoadersContent(div);

            // AppTools.scrollTo('#right-side');

        }).catch(function (err) {
            console.error('Error', err);
        });
    }

    /*
     * Event sur l'auto-submit des formulaires de recherche
     */
    let autoSubmit = function() 
    {
        
        let forms = document.querySelectorAll('.autoSubmit');

        [].forEach.call(forms, function(form) {
            form.addEventListener('change', function(){
                if(form.checkValidity()) {
                    let submit = form.querySelector('[type="submit"]');
                    submit.classList.add('loading');
                    form.submit();
                }
            })
        });
    }

    let initCentre = function()
    {
        let form      = document.getElementById('formCentre');

        form.addEventListener('change', function(event){
            ajaxCentre(1,form,true);
        });

        document.addEventListener('click', function (event) {
            if (!event.target.closest('.item-pagination')) return;

            let elm  = event.target.closest('.item-pagination');
            let page = elm.dataset.page;
            ajaxCentre(page,form,false);
        });

    }

    let ajaxCentre = function (page,form,reset) 
    {
        let data = new FormData(form);
        let div  = document.getElementsByClassName('div_centre')[0];

        data.set('page',page);

        let request = new Promise(function (resolve, reject) {
        
            let xhr = new XMLHttpRequest();
            
            xhr.open('POST', '/centre/ajax', true);
            xhr.responseType = 'text';
            xhr.onload = function () {
                
                if (this.status >= 200 && this.status < 300) {
                    let data;
                    let html = xhr.response;
                    resolve(html);
                }
            };
            xhr.send(data);
        });

        request.then(function (response) {
            if(reset) {
                div.innerHTML = response;
            } else {
                // On supprime l'ancienne div de pagination
                document.getElementsByClassName('ghost')[0].remove();
                div.innerHTML = div.innerHTML + response;
            }
            
        }).catch(function (err) {
            console.error('Error', err);
        });
    }

    let initFiltreModel = function() 
    {
        let div       = document.querySelector('.hold_list_offres');
        let form      = document.getElementById('formModele');
        let allSelect = form.querySelectorAll('select.dimension');
        let allInput  = form.querySelectorAll('input');
        
        form.addEventListener('change', function(event){
            
            let data    = new FormData(form);
            // let indexS  = getIndexFromElems(allSelect,event.target);

            let dataA = new FormData();

            loadingContent(div);

            // Select 
            [].forEach.call(allSelect, function(select,index) {
                dataA.append(select.name,select.value);
                // if(index <= indexS) {
                // }else{
                //     loadingContent(select.parentNode)
                // }
            });

            // Input
            [].forEach.call(allInput, function(input) {
                dataA.append(input.name,input.value);
            })

            let request = new Promise(function (resolve, reject) {
            
                let xhr = new XMLHttpRequest();
                
                xhr.open('POST', '/modele/ajax', true);
                // xhr.responseType = 'text';
                xhr.onload = function () {
                    
                    if (this.status >= 200 && this.status < 300) {
                        let data;
                        let html = xhr.response;
                        resolve(html);
                    }
                };
                xhr.send(dataA);
            });

            request.then(function (response) {
                
                div.innerHTML = response;
                clearLoadersContent()
                
            }).catch(function (err) {
                console.error('Error', err);
            });
        });
    }

    let initFiltreMandataire = function()
    {
        
        let form      = document.getElementById('formMandataire');

        form.addEventListener('change', function(event){
            searchMandataire(form,1,true);
        });  

        document.addEventListener('click', function (event) {
            if (!event.target.closest('.item-pagination')) return;

            let elm  = event.target.closest('.item-pagination');
            let page = elm.dataset.page;
            searchMandataire(form,page,false);
        });

    }

    let searchMandataire = function(form,page,reset) {
        let dataA = new FormData();
        let allSelect = form.querySelectorAll('select');
        let allInput  = form.querySelectorAll('input');

        let div     = document.querySelector('.hold_list_offres .list_items');
        let data    = new FormData(form);
        // let indexS  = getIndexFromElems(allSelect,event.target);
        loadingContent(div);

        // Select 
        [].forEach.call(allSelect, function(select,index) {
            dataA.append(select.name,select.value);
            // if(index <= indexS) {
            // }
        });

        // Input
        [].forEach.call(allInput, function(input) {
            dataA.append(input.name,input.value);
        })

        dataA.set('page',page);

        let request = new Promise(function (resolve, reject) {
        
            let xhr = new XMLHttpRequest();
            
            xhr.open('POST', '/mandataire/ajax', true);
            xhr.responseType = 'text';
            xhr.onload = function () {
                
                if (this.status >= 200 && this.status < 300) {
                    let data;
                    let html = xhr.response;
                    resolve(html);
                }
            };
            xhr.send(dataA);
        });

        request.then(function (response) {
            if(reset) {
                div.innerHTML = response;
            } else {
                // On supprime l'ancienne div de pagination
                document.getElementsByClassName('ghost')[0].remove();
                div.innerHTML = div.innerHTML + response;
            }
            clearLoadersContent(div)
            
        }).catch(function (err) {
            console.error('Error', err);
        });        
    }

    /*
     * Event sur les formulaires de dimensions
     */
    let formDimensions = function() 
    {
        
        let formDimensions = document.querySelectorAll('.dimensions');

        [].forEach.call(formDimensions, function(form) {
            let allSelect = form.querySelectorAll('select.dimension');
            let allInput  = form.querySelectorAll('input:checked,input[type="hidden"]');

            form.addEventListener('change', function(event){
                if(!event.target.classList.contains('dimension')) return;

                // event.preventDefault();
                // event.stopPropagation();
                event.stopImmediatePropagation();

                let data    = new FormData(form);
                let indexS  = getIndexFromElems(allSelect,event.target);

                let dataA = new FormData();

                // Select 
                [].forEach.call(allSelect, function(select,index) {
                    if(index <= indexS) {
                        dataA.append(select.name,select.value);
                    }else{
                        loadingContent(select.parentNode)
                    }
                });

                // Input
                [].forEach.call(allInput, function(input) {
                    dataA.append(input.name,input.value);
                })
                    
                let request = new Promise(function (resolve, reject) {
                
                    let xhr = new XMLHttpRequest();
                    
                    xhr.open('POST', '/ajax/dimensions', true);
                    xhr.responseType = 'json';
                    xhr.onload = function () {
                        
                        if (this.status >= 200 && this.status < 300) {
                            let data;
                            let json = xhr.response;
                            resolve(json);
                        }
                    };
                    xhr.send(dataA);
                });

                request.then(function (response) {

                    if(ie){
                        response = JSON.parse(response);
                    }

                    // On reset les select en laissant la première option
                    [].forEach.call(allSelect, function(select) {
                        select.options.length = 1;
                    });
                    
                    // On remplit les selects
                    Object.keys(response).forEach(function(key) {
                        let select = form.querySelector("[name='"+key+"']");
                        
                        if(select) {
                            [].forEach.call(response[key], function(value) {
                                let option = document.createElement("option");
                                option.text = value;
                                select.add(option);
                            })

                            // On re-remplit la valeur 
                            if(dataA.has(key)) {
                                select.value = dataA.get(key);
                            }
                        }
                    })
                    clearLoadersContent()
                    searchSubmit(true, 1)

                    /**
                     * Fix model form
                     */
                    let formmodel = document.getElementById('formModele');
                    if(formmodel){
                        if ("createEvent" in document) {
                            var evt = document.createEvent("HTMLEvents");
                            evt.initEvent("change", false, true);
                            formmodel.dispatchEvent(evt);
                        }
                        else{
                            formmodel.fireEvent("onchange");
                        }
                    }
                    let formand = document.getElementById('formMandataire');
                    if(formand){
                        if ("createEvent" in document) {
                            var evt = document.createEvent("HTMLEvents");
                            evt.initEvent("change", false, true);
                            formand.dispatchEvent(evt);
                        }
                        else{
                            formand.fireEvent("onchange");
                        }
                    }
                    
                }).catch(function (err) {
                    console.error('Error', err);
                });
            })
        });
           
    }    

    let loadingContent = function (field) {
        if(field){
            field.classList.add('loading-content');
        }
        let btn = document.querySelector('#toggle-filtres');
        if(btn){
            btn.classList.add('loading');
        }
    }

    let clearLoadersContent = function (item) {
        item = item || null;
        if(item){
            item.classList.remove('loading-content');
        }else{
            let items = document.querySelectorAll('.loading-content');
            [].forEach.call(items, function(item){
                item.classList.remove('loading-content');
            })
        }

        let btn = document.querySelector('#toggle-filtres');
        if(btn){
            btn.classList.remove('loading');
        }
    }

    let getIndexFromElems = function (set, elm){
        var setArr = [].slice.call(set);
        for (let i = 0; i < setArr.length; i++) {
            let curr = setArr[i];
            if(curr === elm){
                return i;
            }
        }
    }

    /**
     * Notifications
     */

    let notification = function (message, type) {
        let container = document.getElementById('main-container');
        let div = document.createElement('div');
        div.classList.add('notif-bloc', type);
        div.appendChild(document.createTextNode(message));

        document.body.insertBefore(div, container);
        
        setTimeout(function(){
            div.classList.add('to-show');
        },100);

        setTimeout(function(){
            div.classList.remove('to-show');
            setTimeout(function(){
                div.remove();
            },300);
        },3000);
    }
    

    return {

        /*
        * Main class initializer
        */

        init: function() {
            AppFunctions.initComponents();

            while (pagefunctions.length){
                pagefunctions.shift().call();
            }
        
            if(typeof launchFunction == 'function')
                launchFunction()
        },

        /*
        * Initializes components
        */

        initComponents: function() {
            
            // controlOverlay()
            controlToggles()
            controlResp()
            controlSidebar()
            autoSubmit()
            formDimensions()
            // controlForm()
            // controlSlides()
            let tooltip = new Tooltip({
                theme: "light",
                distance: 20,
                delay: 0
            });

            // loadOverlay({'overlay':'contactform'})

            // $(window).resize(function() {
                // controlResp()
            // })
        },

        /*
         * Filtre page modèle
         */
        initFiltreModel: function() {
            initFiltreModel()
        },

        /*
         * Filtre page recherche
         */
        controlSearch: function() {
            controlSearch()
        },


        /*
         * Filtre page mandataire
         */
        initFiltreMandataire: function() {
            initFiltreMandataire()
        },

        /*
         * Filtre page centre
         */
        initCentre: function() {
            initCentre()
        },

        /**
         * Actions alert
         */
        showAlert: function(message, type) {
            notification(message, type)
        },

        initSidebar: function(main, side) {
            controlSidebar(main,side)
        }
    };

}();

NodeList.prototype[Symbol.iterator] = [][Symbol.iterator];

(function() {

    // AppFunctions.init();    

})();